import React from 'react';
import { Typography } from '@material-ui/core';

const styles = {

    heading: {
        fontWeight: 'bold',
        marginBottom: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: "100px",
        fontSize: '20px',
    },
    text: {
        marginBottom: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '20px',

    },
};

const MissingBookingInfo = () => {
    return (
        <div>
            <Typography style={styles.heading}>Please Contact Front Desk</Typography>
        </div >
    );
};



export default MissingBookingInfo;

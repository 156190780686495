import { useState } from 'react'

/**
 * @param SnackBar
 * handle moreoption icon on listing page
 */
export const useSnackBar = () => {
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [snackBarMessage, setSnackBarMessage] = useState('')
  // const navigate = useNavigate();
  // const history = useHistory();

  const snackFunc = (showText: string, goBack: Boolean, error?: Boolean) => {
    setSnackBarMessage(showText)
    setOpenSnackBar(true)
    !error &&
      setTimeout(
        () => {
          setOpenSnackBar(false)
          // goBack && history.goBack()
        },
        goBack ? 2500 : 5000
      )
  }
  const close = () => {
    setOpenSnackBar(false)
  }
  return {
    openSnackBar,
    snackBarMessage,
    snackFunc,
    close,
  }
}

import 'underscore'
import Compressor from 'compressorjs'
// import { GoogleAPIKey } from 'client'

export const OCRhelper = async (file: File) => {
  let Result: any = null
  setwatermark(file)
  await new Promise(async (resolve, reject) => {
    new Compressor(file, {
      quality: 0.8,
      success(result) {
        let reader = new FileReader()
        reader.readAsDataURL(result)
        reader.onload = async () => {
          Result = await (reader.result as any)?.split('base64,')[1]
          resolve(Result)
        }

        // reader.onload = async () => {
        //   Result = await(reader.result as any)?.split('base64,')[1])
        //   resolve(Result)
        // }

        reader.onerror = error => {
          console.log(error)
        }
      },
      error(err) {
        console.log(err.message)
        reject(err)
      },
    })
  })
  return { ...Result, picture: image, Blob: blob }
}

var image: string | ArrayBuffer | null = null
var blob: any = null

const setwatermark = (file: any) => {
  // console.log("🚀 ~ file: OCRhelper.ts ~ line 36 ~ file", file)
  new Compressor(file, {
    strict: false,
    drew(context: any, canvas: any) {
      context.fillStyle = '#000'
      context.font = '8vh serif'
      context.textAlign = 'center'
      context.fillText('HotelX use only', canvas.width / 2, canvas.height / 2)
    },
    success: function (result: any) {
      var newImage = new Image()
      newImage.src = URL.createObjectURL(result)
      newImage.alt = 'Compressed image'
      let reader = new FileReader()
      reader.readAsDataURL(result)
      reader.onload = () => {
        blob = result
        // console.log("🚀 ~ file: OCRhelper.ts ~ line 54 ~ reader", reader)
        image = reader.result
        //save file in database
      }
      reader.onerror = error => {
        console.log(error)
      }
    },
    error: function (err) {
      window.alert(err.message)
    },
  })
}

export function b64toBlob(b64Data: any, contentType: any, sliceSize: any) {
  contentType = contentType || ''
  sliceSize = sliceSize || 512

  var byteCharacters = atob(b64Data)  
  let byteArrays: any = []

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize)

    var byteNumbers = new Array(slice.length)
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    let byteArray: any = new Uint8Array(byteNumbers) as any

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}


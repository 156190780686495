import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MuiTelInput } from "mui-tel-input";
import React, { Reducer, useReducer, useState } from "react";
import { HiArrowNarrowLeft } from "react-icons/hi";
import { PatternFormat } from "react-number-format";
import { useLocation, useNavigate } from "react-router";
import Webcam from "react-webcam";
import { OCRhelper } from "../Components/Hooks/OCRhelper";
import { useSnackBar } from "../Components/Hooks/useSnackBar";
import { validatePassword } from "../Components/PasswordValidator";
import SnackBarMsg from "../Components/SnackBar/SnackBarMsg";
import defaultPIC from "../Components/logo/UploadPic.png";
import {
  Country,
  Nationality,
  usePreRegBookingInfoQuery,
  // usePreRegistrationUpdateMutation,
  // usePublicSuperUserQuery,
} from "../generated/graphql";
interface FormProps {
  FullName: string;
  PhoneNo: string;
  Email: string;
  Nationality: any;
  NRIC: string;
  // ICNo:string
  Address?: string;
  Country?: string;
  State?: string;
  City?: string;
  Postcode?: string;
  Blob?: null | any;
  IcPhoto?: any;
  Scanning: boolean;
  CancelDialog: boolean;
  Password: string;
  OCRResult?: null | any;
  AccountID?: string;
  S_User?: string;
  IsPreRegistration?: boolean;
}
declare module "@mui/material/styles" {
  //custom checkbox color
  interface Theme {
    status: { danger: string };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: { danger?: string };
  }
}

let NationalityEnum: string[] = Object.values(Nationality).map((i) => {
  return i.replace(/_/g, " ");
});
let CountryEnum: any = Object.values(Country).map((i) => {
  return i.replace(/_/g, " ");
});

export function BookingForm() {
  const dateFormat = require("dateformat");
  const location = useLocation();
  const localstate: any = location.state;
  const navigate = useNavigate();

  //SuperUser API
  // const { data: { PublicSuperUser } = { PublicSuperUser: [] } } =
  //   usePublicSuperUserQuery({
  //     variables: {
  //       RegistrationID: localstate?.URLBookingID || " ",
  //     },
  //     // fetchPolicy: 'no-cache',
  //   });
  // const s_user = `${PublicSuperUser[0]?.ID}`;
  // const accountID = `${PublicSuperUser[0]?.accountID}`;

  
  interface IAction<TType = string, TPayload = any> {
    type: TType;
    payload: TPayload;
  }

  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar();
  //guest conntact update mutation
  // const [
  //   PreRegistrationUpdate,
  //   // { loading: loadingUpdate },
  // ] = usePreRegistrationUpdateMutation({
  //   onError: (error:any) => {
  //     if (error.message) {
  //       snackFunc(error.message, false);
  //     }
  //   },
  //   onCompleted: (data:any) => {
  //     if (data.PreRegistrationUpdate) {
  //       snackFunc("Update Successfully", false);
  //       setTimeout(() => {
  //         {
  //           navigate(-1);
  //         }
  //       }, 2000);
  //     } else {
  //       snackFunc("Update Fail", false);
  //     }
  //   },
  // });

  //handle ic picture
  const ICGalleryPic = localstate?.Registration?.Guest?.ICGallery?.ImageURL;
  const [uploadFile, setUploadFile] = useState(ICGalleryPic ?? defaultPIC);
  const [targetfile, setTargefile] = useState(null);
  const [webcampic, setWebcampic] = useState("");
  const initialState: FormProps = {
    FullName: localstate?.Registration?.Guest?.Contact?.FullName || "",
    PhoneNo: localstate?.Registration?.Guest?.Contact?.MobileNo || "",
    Email: localstate?.Registration?.Guest?.Contact?.Email || "",
    Nationality: localstate?.BookingDetails?.Contact?.Nationality || "",
    NRIC: localstate?.Registration?.Guest?.Contact?.NRIC || "",
    Address: localstate?.Registration?.Guest?.Contact?.Address?.address || "",
    State: localstate?.Registration?.Guest?.Contact?.Address?.state || "",
    City: localstate?.Registration?.Guest?.Contact?.Address?.city || "",
    Country: localstate?.Registration?.Guest?.Contact?.Address?.country || "",
    Postcode: localstate?.Registration?.Guest?.Contact?.Address?.postCode || "",
    Password: "",
    IcPhoto: null,
    Scanning: false,
    CancelDialog: false,
    // Blob: uploadFile,
    // AccountID: accountID,
    // S_User: s_user,
    IsPreRegistration: localstate?.Registration?.IsPreRegistration || "",
  };

  const reducer: Reducer<FormProps, IAction> = (state, action) => {
    switch (action.type) {
      case "CloseDialog":
        return { ...state, CancelDialog: false };
      case "reset":
        return initialState;
      default:
        return { ...state, [action.type]: action.payload };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const [ContactInformation2, setContactInformation2] = useState(initialState);

  //handledialog
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setUploadFile(null);
    setOpen(true);
  };
  const handleClose = () => {
    setUploadFile(null);
    setOpen(false);
  };

  //webcam resolution
  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  //handle webcam
  const webcamRef: any = React.useRef(null);
  const captureIC = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    fetch(imageSrc)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then(async (buffer) => {
        let picURL: any = await new File([buffer], "FullName", {
          type: "image/png",
        });

        let OCRhelper2 = await OCRhelper(
          new File([buffer], "FullName", { type: "image/png" })
        );
        console.log("OCRhelper2webcam", OCRhelper2.Blob);
        picURL.src = URL.createObjectURL(picURL);
        await setWebcampic(picURL.src);
        await dispatch({
          type: "IcPhoto",
          payload: OCRhelper2.Blob,
        });
      });
  }, [webcamRef]);

  //handle upload pic
  const fileToDataUri = (file: any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });

  const [dataUri, setDataUri] = useState("");
  const onChange = (file: any) => {
    if (!file) {
      setDataUri("");
      return;
    }
    fileToDataUri(file).then((dataUri) => {
      setDataUri(dataUri as any);
    });
  };
  console.log(dataUri, "dataurl");

  const handleUploadImg = async (e: any) => {
    setTargefile(e.target.files[0]);

    let OCRhelper2 = await OCRhelper(e.target.files[0]);
    console.log("OCRhelper2", OCRhelper2.Blob);
    let ImageFile = URL.createObjectURL(e.target.files[0]);
    setUploadFile(ImageFile);
    // console.log("@targetfile", e.target.files);
    // setContactInformation2({ ...ContactInformation2, IcPhoto: OCRhelper2.Blob })
    await dispatch({
      type: "IcPhoto",
      payload: OCRhelper2.Blob,
    });
  };
  const inputfile: any = React.useRef(null);

  // const onSubmit = () => {
  //   PreRegistrationUpdate({
  //     variables: {
  //       ContactInput: {
  //         ID: localstate?.Registration?.Guest?.Contact?.ID,
  //         FullName: ContactInformation2?.FullName,
  //         NRIC: ContactInformation2?.NRIC,
  //         Nationality: ContactInformation2?.Nationality,
  //         MobileNo: ContactInformation2?.PhoneNo,
  //         Email: ContactInformation2?.Email,
  //         Address: {
  //           address: ContactInformation2?.Address,
  //           country: ContactInformation2?.Country,
  //           city: ContactInformation2?.City,
  //           state: ContactInformation2?.State,
  //           postCode: ContactInformation2?.Postcode,
  //         },
  //       },
  //       AttachmentInput: state?.IcPhoto,
  //       BookingID: localstate?.BookingDetails?.ID,
  //       RegistrationID: localstate?.Registration?.ID,
  //       Password: ContactInformation2.Password,
  //       S_User: s_user,
  //       AccountID: accountID,
  //       IsPreRegistration: true,
  //     },
  //   });
  // };

  const [pswrd, setPswrd] = useState("");
  const [chk, setChk] = useState({
    checkbox1: false,
    checkbox2: false,
  });
  return (
    <>
      <AppBar position="fixed" sx={{ background: "white" }}>
        <Toolbar sx={{ width: "100%" }}>
          <Box pr="12px" alignItems={"center"}>
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
            >
              {/* <HiArrowNarrowLeft fontSize={"24px"} /> */}
            </IconButton>
          </Box>
          <Typography fontSize="16px" fontWeight={600}>
            Pre Registration
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ minHeight: "60px" }} />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignContent: "flex-start",
          p: 1,
          m: 1,
        }}
      >
        <Box width={1200} margin="auto" bgcolor={"#F7FAF7"}>
          {" "}
          <Typography fontSize="16px" fontWeight={600} mb="12px">
            Room Info
          </Typography>
          <Card
            sx={{
              mb: "16px",
              borderRadius: "5px",
              boxShadow:
                "0 3px 1px -2px transparent, 0 1px 10px 2px rgb(0 0 0 / 5%), 0 0 5px 0 rgb(0 0 0 / 10%)",
            }}
          >
            <CardContent sx={{ ":last-child": { pb: "12px" } }}>
              <Box width="100%">
                <Box width="100%" display="flex">
                  <Box pr="12px">
                    <Box
                      component="img"
                      src={
                        localstate?.selectedRoom?.RoomTypeGallery[0]?.Gallery
                          ?.ImageURL || ""
                      }
                      alt={"landing-logo"}
                      loading="lazy"
                      sx={{
                        width: "100px",
                        height: "52px",
                        maxWidth: "100px",
                        borderRadius: "5px",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                  <Box>
                    <Typography fontSize="16px" fontWeight={600} noWrap>
                      {localstate?.selectedRoom?.Description || ""}
                    </Typography>
                    <Typography fontSize="14px" fontWeight={500} noWrap>
                      {dateFormat(
                        localstate?.Registration?.ArrivalDate,
                        "dd mmm yyyy"
                      )}
                      {" - "}
                      {dateFormat(
                        localstate?.Registration?.DepartureDate,
                        "dd mmm yyyy"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <Typography fontSize="16px" fontWeight={600} mb="12px">
            Personal Info
          </Typography>
          <Box mb="16px">
            <Box width="100%" mb="16px" sx={{ display: "flex" }}>
              <Autocomplete
                fullWidth
                id="nationality"
                options={NationalityEnum}
                value={ContactInformation2.Nationality}
                getOptionLabel={(option) => option || ""}
                onChange={(_, data) =>
                  setContactInformation2({
                    ...ContactInformation2,
                    Nationality: data as string,
                  })
                }
                renderInput={(params) => (
                  <TextField required {...params} label="Nationality" />
                )}
              />
            </Box>
            <Box mb="16px" sx={{ display: "flex" }}>
              <>
                {ContactInformation2.Nationality === "Malaysian" ? (
                  <PatternFormat
                    required
                    label="IC"
                    displayType="input"
                    type="text"
                    customInput={TextField}
                    fullWidth 
                    value={ContactInformation2?.NRIC}
                    name="NRIC"
                    onChange={(e: any) =>
                      setContactInformation2({
                        ...ContactInformation2,
                        NRIC: e.target.value,
                      })
                    }
                    format="######-##-####"
                    mask="_"
                  />
                ) : (
                  <TextField
                    required
                    label="Passport No"
                    fullWidth
                    InputProps={{}}
                    value={ContactInformation2?.NRIC}
                    name="NRIC"
                    onChange={(e) =>
                      setContactInformation2({
                        ...ContactInformation2,
                        NRIC: e.target.value,
                      })
                    }
                  />
                )}
              </>
            </Box>
            <Box mb="16px">
              <TextField
                required
                label="Address"
                fullWidth
                type="text"
                value={ContactInformation2?.Address}
                name="Address"
                onChange={(e) =>
                  setContactInformation2({
                    ...ContactInformation2,
                    Address: e.target.value,
                  })
                }
              />
            </Box>
            <Box width="100%" display="flex">
              <Box width="50%" mb="16px" pr="6px">
                <Autocomplete
                  id="country"
                  options={CountryEnum}
                  value={ContactInformation2.Country}
                  getOptionLabel={(option) => option || ""}
                  onChange={(_, data) =>
                    setContactInformation2({
                      ...ContactInformation2,
                      Country: data as string,
                    })
                  }
                  renderInput={(params) => (
                    <TextField required {...params} label="Country" />
                  )}
                />
              </Box>
              <Box width="50%" mb="16px" pl="6px">
                <TextField
                  label="State"
                  fullWidth
                  type="text"
                  value={ContactInformation2?.State}
                  name="State"
                  onChange={(e) =>
                    setContactInformation2({
                      ...ContactInformation2,
                      State: e.target.value,
                    })
                  }
                />
              </Box>
            </Box>
            <Box width="100%" display="flex">
              <Box width="50%" mb="16px" pr="6px">
                <TextField
                  label="City"
                  fullWidth
                  value={ContactInformation2?.City}
                  type="text"
                  name="City"
                  onChange={(e) =>
                    setContactInformation2({
                      ...ContactInformation2,
                      City: e.target.value,
                    })
                  }
                />
              </Box>
              <Box width="50%" mb="16px" pl="6px">
                <PatternFormat
                  displayType="input"
                  type="text"
                  customInput={TextField}
                  fullWidth
                  value={ContactInformation2?.Postcode}
                  label="Postcode"
                  name="Postcode"
                  onChange={(e) =>
                    setContactInformation2({
                      ...ContactInformation2,
                      Postcode: e.target.value,
                    })
                  }
                  format="#####"
                />
              </Box>
            </Box>
            <Box mb="16px">
              <MuiTelInput
                fullWidth
                defaultCountry="MY"
                value={ContactInformation2?.PhoneNo}
                onChange={(e) =>
                  setContactInformation2({
                    ...ContactInformation2,
                    PhoneNo: e,
                  })
                }
                inputProps={{ maxLength: 20 }}
              />
            </Box>
            <Box mb="16px">
              <TextField
                required
                label="Email Address"
                fullWidth
                value={ContactInformation2?.Email}
                name="Email"
                type="email"
                onChange={(e) =>
                  setContactInformation2({
                    ...ContactInformation2,
                    Email: e.target.value,
                  })
                }
              />
            </Box>
          </Box>
          <Typography fontSize="16px" fontWeight={600}>
            NRIC
          </Typography>
          <Typography fontSize="12px" fontWeight={500} mb="12px">
            Take a clear photo of the front of your IC.
          </Typography>
          <Box width="100%" textAlign="center">
            <Box display="flex" flexGrow={1}></Box>
            <Box sx={{ display: "inline-flex" }}>
              {uploadFile === ICGalleryPic ? (
                <img
                  src={ICGalleryPic}
                  alt="empty"
                  loading="lazy"
                  height={200}
                />
              ) : webcampic === "" ? (
                <img src={uploadFile} alt="empty" loading="lazy" height={200} />
              ) : (
                <img src={webcampic} alt="empty" loading="lazy" height={200} />
              )}
            </Box>
            <Box display="flex" flexGrow={1}></Box>
          </Box>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Typography sx={{ fontWeight: "bold" }}>
                {"Take a Photo"}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {
                  <Webcam
                    audio={false}
                    // height={720}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={"100%"}
                    videoConstraints={videoConstraints}
                  />
                }
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                fullWidth
                onClick={(e) => {
                  captureIC();
                  onChange(e);
                  handleClose();
                  setUploadFile(null);
                }}
                color="primary"
              >
                Capture
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            width="100%"
            display="flex"
            justifyContent={"space-between"}
            p="0px 25px"
            mb="16px"
          >
            <Box pr="6px" width="100%">
              <Button
                variant="outlined"
                fullWidth
                onClick={handleClickOpen}
                onChange={(e) => {
                  setWebcampic("");
                  uploadFile("");
                }}
                color="primary"
              >
                Open Camera
              </Button>
            </Box>
            <Box pl="6px" width="100%">
              <Button
                variant="contained"
                component="label"
                fullWidth
                color="primary"
                onClick={(e: any) => inputfile.current.Click()}
              >
                Upload
                <input
                  hidden
                  ref={inputfile}
                  type="file"
                  onChange={async (e: any) => {
                    // handleScan(e)
                    handleUploadImg(e);
                    onChange(e);
                    setWebcampic("");
                    await dispatch({
                      type: "icPhoto",
                      payload: null,
                    });
                    await dispatch({
                      type: "icPhoto",
                      payload: e.target.files[0],
                    });
                  }}
                />
              </Button>
            </Box>
          </Box>
          <FormGroup sx={{ mb: "16px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  checked={chk?.checkbox1}
                  onChange={() => {
                    chk?.checkbox1 === true
                      ? setChk({ ...chk, checkbox1: false })
                      : setChk({ ...chk, checkbox1: true });
                  }}
                />
              }
              label={
                <Typography fontSize="13px" fontWeight={500}>
                  I acknowledge and accept the hotel{" "}
                  <Typography
                    color="primary"
                    fontSize="13px"
                    fontWeight={500}
                    component="span"
                  >
                    Terms and Condition
                  </Typography>
                </Typography>
              }
            />
          </FormGroup>
          <Box width="100%" mb={"16px"}>
            <Typography fontSize="16px" fontWeight={600}>
              Guest App
            </Typography>
            <Typography fontSize="12px" fontWeight={500} mb="12px">
              Your Contact No. is the default login ID.{" "}
            </Typography>
            <Box mb="16px">
              <TextField
                type="password"
                label="Guest App Password."
                fullWidth
                size="small"
                style={{ backgroundColor: "white" }}
                // defaultValue="Enter a minimum of 6 digit"
                value={pswrd}
                onChange={(e) => {
                  setPswrd(e.target.value);
                }}
                // error={validatePassword(pswrd).length > 0}
                helperText={
                  ContactInformation2.Password.length > 0
                    ? validatePassword(pswrd).map((x, i) => {
                        return <p style={{ color: "red" }}>{`${x}`}</p>;
                      })
                    : null
                }
              />
            </Box>
            <Box mb="16px">
              <TextField
                type="password"
                label="Re-confirm Password"
                fullWidth
                size="small"
                style={{ backgroundColor: "white" }}
                onChange={(e) => {
                  // setWebcampic(null);
                  setContactInformation2({
                    ...ContactInformation2,
                    Password: e.target.value,
                  });
                }}
                // error={
                //     ContactInformation2?.Password.length > 0
                //     && pswrd !== ContactInformation2?.Password}
                helperText={
                  ContactInformation2?.Password.length > 0 &&
                  pswrd !== ContactInformation2?.Password ? (
                    <p style={{ color: "red" }}>• Password not match</p>
                  ) : null
                }
              />
            </Box>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    checked={chk?.checkbox2}
                    onChange={() => {
                      chk?.checkbox2 === true
                        ? setChk({ ...chk, checkbox2: false })
                        : setChk({ ...chk, checkbox2: true });
                    }}
                  />
                }
                label={
                  <Typography fontSize="13px" fontWeight={500}>
                    I acknowledge and accept the hotel{" "}
                    <Typography
                      color="primary"
                      fontSize="13px"
                      fontWeight={500}
                      component="span"
                    >
                      Terms and Condition
                    </Typography>
                  </Typography>
                }
              />
            </FormGroup>
          </Box>
          <Button
            variant="contained"
            fullWidth
            disabled={
              ContactInformation2?.Password.length < 8 ||
              pswrd?.length < 8 ||
              pswrd !== ContactInformation2?.Password ||
              chk?.checkbox1 === false ||
              (chk?.checkbox1 && chk?.checkbox2) === false ||
              ContactInformation2?.Country === "" ||
              ContactInformation2?.Address === "" ||
              ContactInformation2.NRIC === "" ||
              ContactInformation2?.Email === "" ||
              validatePassword(pswrd).length > 0
            }
            // onClick={() => onSubmit()}
          >
            Save
          </Button>
        </Box>
      </Box>
      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />
    </>
  );
}

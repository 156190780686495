import { createTheme } from "@mui/material/styles";

export const Theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#FE7540",
    },
    secondary: {
      main: "#32302F",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          background: "#F7FAF7",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderRadius: `10px 10px 10px 10px`,
            },
          },

          background: "white",
          // boxShadow:
          //   "0 3px 1px -2px transparent, 0 1px 10px 2px rgb(0 0 0 / 5%), 0 0 5px 0 rgb(0 0 0 / 10%)",
          borderRadius: 10,
          // padding: "8px 0px 8px 8px",
          // borderRadius: "4px 0 0 4px",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          background: "white",
          // boxShadow:
          //   "0 3px 1px -2px transparent, 0 1px 10px 2px rgb(0 0 0 / 5%), 0 0 5px 0 rgb(0 0 0 / 10%)",
          borderRadius: "6px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          textTransform: "capitalize",
        },
      },
    },
  },
});

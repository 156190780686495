import { styled } from "@mui/system";
import { Box } from "@mui/system";
import React from "react";
import { css } from '@emotion/react';
interface MainWrapperProps {
  children: React.ReactNode;
}
export const MainWrapper = ({ children }: MainWrapperProps) => {
  const MainContent = styled(Box)`
    ${({ theme }: any) => `
  position: relative;
  flex-grow: 1;
  padding: 12px;
  margin-bottom: 60px;
  transition: ${theme.transitions?.create(["margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  })};
  @media screen and (min-width: 600px) {
     
        max-width: 1200px;
        margin: 0 auto;
        width: 80vw;
        padding: 24px;
  }
  `}
  `;

  const mediaQueryStyles:any = css`
    @media screen and (min-width: 600px) {
      max-width: 1200px;
      margin: 0 auto;
      width: 80vw;
      padding: 24px;
    }
  `;
  return (
   
    <Box  style={{
      display: 'flex',
      position: 'relative',
 
      padding: '12px',
      marginBottom: '12px',
      margin:'auto',
      width:'100%'
     
     
    }}>
      {children}
    </Box>
 
  );
};

import { AddCircle, PersonAdd, RemoveCircle } from '@mui/icons-material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  List,
  styled,
  TextField
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import React, { Reducer, useCallback, useEffect, useReducer, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import CompanyLogo from '../../src/Components/Logo';
import { Footer } from "../Components/Footer";
import Loading from "../Components/Loading/Loading";
import {
  // useBookingDetailsQuery,
  useHotelDetailsQuery,
  useExpressCheckinConfirmationMutation,
  usePreRegBookingInfoLazyQuery
} from "../generated/graphql";
import { MainWrapper } from "./MainWrapper";
import { subDays, isBefore } from "date-fns";

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}
interface FormProps {
  contact: any
  fullname: string
}
const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function Main() {
  const dateFormat = require("dateformat");
  const { localtstate }: any = useLocation();
  const location = useLocation();
  const history = useNavigate();

  //to get hotelid and booking id from url
  const queryParameters = new URLSearchParams(window.location.search);
  const URLBookingID = queryParameters.get("Booking") as string;
  const URLHotelID = queryParameters.get("Hotel") as string;

  //BookingDetails API
  // const {
  //   refetch,
  //   loading: loadingDetails,
  //   data: { BookingDetails } = { BookingDetails: null },
  // } = useBookingDetailsQuery({
  //   variables: {
  //     ID: URLBookingID || " ",
  //     HotelID: URLHotelID || " ",
  //   },
  //   fetchPolicy: "no-cache",
  // });
  interface IAction<TType = string, TPayload = any> {
    type: TType;
    payload: TPayload;
  }
  const [contact, setContact] = useState([] as any)
  const [fn, setfn] = useState('test')
  const reducer: Reducer<FormProps, IAction> = (state, action) => {
    switch (action.type) {
      case "CloseDialog":
        return { ...state, CancelDialog: false };
      case "reset":
        return initialState;
      default:
        return { ...state, [action.type]: action.payload };
    }
  };
  const initialState: FormProps = {
    contact: contact || "",
    fullname: ''
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [guest, setGuest] = useState([] as any)
  // const { loading, data: { PreRegBookingInfo } = { PreRegBookingInfo: [] } } =
  //   usePreRegBookingInfoQuery({
  //     // variables: {
  //     //   HotelID: URLHotelID,
  //     //   BookingID: URLBookingID,
  //     // },
  //     fetchPolicy: 'no-cache',
  //   });

  const {
    loading: HotelLoadings,
    data: { HotelDetails } = { HotelDetails: null },
  } = useHotelDetailsQuery({
    variables: {
      HotelID: URLHotelID,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    PreRegInfo,
    {
      loading: Loadings,
      data: { PreRegBookingInfo } = {
        PreRegBookingInfo: []
      },
    },
  ] = usePreRegBookingInfoLazyQuery({

    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (URLHotelID !== null && URLBookingID !== null) {
      PreRegInfo(
        {
          variables: {
            HotelID: URLHotelID,
            BookingID: URLBookingID,
          },
        }
      )
    }
  }, [URLHotelID, URLBookingID])



  console.log("apa?", URLHotelID, URLBookingID)

  useEffect(() => {
    if (URLHotelID !== null && URLBookingID !== null) { }
  }, [URLHotelID, URLBookingID])

  const [
    ExpressCheckinConfirmation,
    // { loading:ExpressCheckinConfirmation },
  ] = useExpressCheckinConfirmationMutation({
    onError: error => {
      console.log('error', error)
    },
    onCompleted: data => {
      // console.log('data', data)
      // if (data?.activateUser && token) {
      //   setTimeout(() => {
      //     history.push(`/user/create-password/${token}`)
      //   }, 5000)
      // } else {
      //   console.log('mutation return false')
      // }
    },
  })




  let arr: any = []
  useEffect(() => {
    PreRegBookingInfo?.result?.map((t: any) => {
      arr.push({
        ID: t.RoomType,
        SharerNo: t.SharerNo,
        IsExpanded: false,
        MaxGuest: t.Registrations[0]?.RoomType?.MaxGuest

      })
    })
    setExpanded(arr)
  }, [PreRegBookingInfo])
  const [expanded, setExpanded] = useState(arr)
  const handleExpandClick = (id: any) => {
    let NewArray = expanded.map((f: any) => {
      if (f.SharerNo === id) {
        {console.log(id, "ada")}
        return {
          ID: f.ID,
          SharerNo: f.SharerNo,
          IsExpanded: f.IsExpanded === true ? false : true,
          MaxGuest: f.MaxGuest
        }
      }
      else {
        return {
          ...f
        }
      }
    })
    setExpanded(NewArray);
  };

  const SortRegBySharerNo = PreRegBookingInfo?.result?.sort((a, b) => 
    a.Registrations[0]?.SharerNo - b.Registrations[0]?.SharerNo
  );

  console.log(SortRegBySharerNo, 'SortRegBySharerNo')

  useEffect(() => {
    let GuestArr: any = []

    if (PreRegBookingInfo && guest.length === 0) {
      SortRegBySharerNo?.map((t: any, i: number) => {
        GuestArr.push({
          ContactInfo: t.Registrations.map((y: any, i2: number) => {
            return {
              ...y,
              Order: i2 + 1,
              RoomType: t.RoomType,
              RoomDescription: y.RoomType?.Description,
              OldFullName: y.Guest?.Contact?.FullName,
            }
          }),
          RoomType: t.RoomType,
          SharerNo: t.SharerNo,
        })
      })
      setGuest(GuestArr)
      let ContactArr: any = []
      GuestArr.map((m: any) => {
        ContactArr.push(...m.ContactInfo)
      })
      setContact(ContactArr)
    }
  }, [PreRegBookingInfo])


  const [maxguest, setMaxGuest] = useState()
  //Add Guest
  const handlePlus = (i: any) => {
    {console.log(i,guest,expanded,"adakah")}
    let noguest = guest.find((gg: any) => gg.SharerNo === i)?.ContactInfo?.length
    const maxGuest = expanded.find((ex: any) => ex.SharerNo === i)?.MaxGuest;
    setMaxGuest(maxGuest)
    if (noguest >= maxGuest) {
      // setMaxGuest(true)
      return false;
    }

    const result = guest?.map(((g: any) => {
      if (g.SharerNo === i) {
        return {
          ContactInfo: [{
            RoomType: g.RoomType,
            Order: noguest + 1,
            Mode: 'add',
            SharerNo: g.SharerNo,
            Guest:
            {
              Contact: {
                Email: "",
                FullName: ""
              }
            }
          }, ...g.ContactInfo]
          ,
          RoomType: g.RoomType,
          SharerNo: g.SharerNo,
        }
      }
      else {
        return {
          ...g
        }
      }
    }))
    setGuest(result.map((sort: any) => {
      return {
        ...sort,
        ContactInfo: sort?.ContactInfo?.sort((a: any, b: any) => a.Order - b.Order)
      }
    }))
    // dispatch({ type: 'RoomAvailable', payload: results })
  }

  //Remove Guest
  const handleMinus = (SharerNo: any) => {
    console.log(SharerNo,"adaa")
    const ContactInfoLength: number = guest?.find((y: any) => y?.SharerNo === SharerNo)?.ContactInfo?.length
    const UpdatedList = guest?.find((z: any) => z.SharerNo === SharerNo)?.ContactInfo.filter((item: any) => item.Order !== ContactInfoLength)
    const AllGuest = guest?.map((g: any) => {
      if (g.SharerNo === SharerNo) {
        return {
          ...g,
          ContactInfo: UpdatedList
        }
      }
      else {
        return {
          ...g
        }
      }

    })
    setGuest(AllGuest)
  }


  const onSubmit = () => {
    let editarr = contact
    let addarr = contact
    let inputEdit: any = []
    let inputAdd: any = []

    editarr.filter((t: any) => t.ID).map((g: any) => {
      inputEdit.push({
        RegistrationID: g.ID,
        ContactID: g.Guest.Contact.ID,
        FullName: g.Guest.Contact.FullName,
        Email: g.Guest.Contact.Email,
        OldFullName: g.OldFullName
      })
    })

    addarr.filter((t: any) => t.Mode === 'add').map((g: any) => {
      console.log(guest?.find((x: any) => x.RoomType === g.RoomType)?.ContactInfo?.find((c: any) => c.IsPrincipal)?.ID, 'test')
      inputAdd.push({
        RegistrationID: guest?.find((x: any) => x.SharerNo === g.SharerNo)?.ContactInfo?.find((c: any) => c.IsPrincipal)?.ID,
        FullName: g.Guest.Contact.FullName,
        Email: g.Guest.Contact.Email
      })
    })

    ExpressCheckinConfirmation({
      variables: {
        BookingID: URLBookingID,
        HotelID: URLHotelID,
        EditConfirmationInput: inputEdit,
        AddConfirmationInput: inputAdd
      },
    }).then((r: any) => {
      if (r.data.ExpressCheckinConfirmation === true) {
        history('/pre-checkin/view', { state: { BookingID: URLBookingID, HotelID: URLHotelID, Guest: guest[0]?.ContactInfo?.length, HotelDetails: HotelDetails } })
      }
    })
  }

  const handleChange = useCallback((event: any, fieldIndex: any, subfieldIndex: any) => {
    const newValues = [...guest];
    newValues[fieldIndex].ContactInfo[subfieldIndex].Guest.Contact = { ...event };
    setGuest(newValues);

    let inputcontact: any = []
    newValues.map((m: any) => {
      inputcontact.push(...m.ContactInfo)
    })
    setContact(inputcontact)

  }, [guest])

  let dates; 
  
  if (!HotelLoadings) {

    const NACutOffTime = new Date(HotelDetails?.NAProcessTime).getHours()
    const NATime = new Date().setHours(NACutOffTime)
    const TodayDate = new Date()
    const PreviousDate = subDays(new Date(), 1)

    const newDate = isBefore(new Date(TodayDate), new Date(NATime)) ? PreviousDate : TodayDate;

    dates = dateFormat(newDate, "dd mmm yyyy") > dateFormat(PreRegBookingInfo?.ArrivalDate, "dd mmm yyyy")
    
  }

  if (!URLHotelID || !URLBookingID || !Loadings && (PreRegBookingInfo?.BookingStatus !== "Booked" || dates === true)) {
    history("/missing-booking-info");
  }

  console.log('state', state)
  console.log('xxxguest', guest)
  return HotelLoadings ? <Loading/> : (
    <>

      <MainWrapper>

        <Box
          sx={{ p: 2 }}
          justifyContent="center"
          alignItems="center"
          width={"400px"}
          margin="auto"
          bgcolor={"#F7FAF7"}
        >
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={16}>
              <Box display="flex" mb="12px" alignItems="center" justifyContent="center">
                <Box display="flex" mb="12px" alignItems="center" justifyContent="center">
                  <img
                    src={HotelDetails?.LogoImage?.ImageURL as any}
                    alt={`IMG`}
                    loading="lazy"
                    width="180px"
                  />
                </Box>
              </Box>
              <Typography fontSize="17px" fontWeight={600} mb="8px" align="center">
                Welcome To {HotelDetails?.HotelName}
              </Typography>
              <Typography fontSize="13px" fontWeight={500} mb="12px" color="black" align="center">
                Kindly complete the form below
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" height={20} mb="12px">
            <Typography fontSize="14px" fontWeight={600} >
              Booking Info
            </Typography>
          </Box>

          <Card
            sx={{
              mb: "14px",
              borderRadius: "4px",
              boxShadow:
                "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
            </Box>
            <CardContent
              sx={{
                ":last-child": { p: "10px" },
              }}
            >
              <Box width="100%" display="flex" mb="10px" >
                <Box width="50%" display="flex">
                  <Typography fontSize="14px" fontWeight={500} color="black" align="left" >
                    Booking No.
                  </Typography>
                </Box>
                <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                  <Typography fontSize="14px" fontWeight={500} color="secondary">
                    {PreRegBookingInfo?.BookingNo}
                  </Typography>
                </Box>
              </Box>
              <Box width="100%" display="flex">
                <Box width="50%" display="flex" alignItems={"center"}>
                  <Typography fontSize="14px" fontWeight={500} color="black" >
                    No of Room(s)
                  </Typography>
                </Box>
                <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                  <Typography fontSize="14px" fontWeight={500} color="secondary">
                    {PreRegBookingInfo?.result?.length}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>

          <Typography fontSize="14px" fontWeight={600} mb="10px">
            Registration Info
          </Typography>


          <List className="core-list reset-listitem ">

            <div className="rm-padding p-10 ">
              {guest?.map((x: any, i: any) => {
                { console.log(expanded, "exp") }
                return (
                  <>
                    <div key={x.RoomType}>
                      <Card
                        sx={{
                          mb: "14px",
                          borderRadius: "4px",
                          boxShadow:
                            "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
                        }}
                      >
                        <CardActions key={x.SharerNo} disableSpacing style={{ backgroundColor: "#fff4dc" }}>
                          <Box width="100%">
                            <Box width="100%" display="flex" sx={{ margin: "5px" }}>
                              <Typography fontSize="14px" fontWeight={600} color="#f5ab35" align="left" >
                                {"Room " + Number(i + 1) + " - " + x.ContactInfo[0]?.RoomDescription}
                              </Typography>
                            </Box>

                            <Box width="100%" display="flex">
                              <Box width="100%" display="flex">
                                <Typography fontSize="14px" fontWeight={500} color="black" align="left" style={{ paddingTop: '10px' }}>
                                  {dateFormat(x.ContactInfo[0]?.ArrivalDate, "dd mmm yyyy")}  -  {dateFormat(x.ContactInfo[0]?.DepartureDate, "dd mmm yyyy")}
                                </Typography>
                              </Box>

                              <Box key={x.SharerNo} display="flex" sx={{ justifyContent: 'flex-end'}}>
                                <IconButton
                                  style={{ pointerEvents: 'none' }}>
                                  <PersonAdd sx={{ fontSize: 20, color: "black" }} />
                                </IconButton>

                                <div style={{ paddingLeft: '' }}>

                                </div>
                                <IconButton
                                  disabled={
                                    x?.ContactInfo?.length === PreRegBookingInfo?.result?.find((o: any) => o.SharerNo === x.SharerNo)?.Registrations?.length
                                    || expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded === false
                                  }
                                  aria-label="delete"
                                  // size="small"
                                  onClick={() => handleMinus(x?.SharerNo)}
                                  sx={{ margin: 0 }}
                                >
                                  <RemoveCircle sx={{
                                    fontSize: 20,
                                    color: x?.ContactInfo?.length === PreRegBookingInfo?.result?.find((o: any) => o.SharerNo === x.SharerNo)?.Registrations?.length
                                      ||
                                      expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded === false
                                      ? "#D3D3D3"
                                      : "#f5ab35"
                                  }} />
                                </IconButton>

                                <Typography fontSize="14px" fontWeight={600} color="black" marginTop={1} >
                                  {x?.ContactInfo?.length}
                                </Typography>

                                <IconButton
                                  aria-label="delete"
                                  // size="small"
                                  onClick={() => handlePlus(x.SharerNo)}
                                  disabled={expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded === false}
                                  sx={{ margin: 0 }}
                                >
                                  <AddCircle
                                    sx={{ fontSize: 20, color: expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded === false || maxguest === guest[0]?.ContactInfo?.length ? "#D3D3D3" : "#f5ab35" }}

                                  />
                                </IconButton>
                                <ExpandMore
                                  expand={expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded}
                                  onClick={(SharerNo) => handleExpandClick(x.SharerNo)}
                                  aria-expanded={expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded}
                                  aria-label="show more"
                                  sx={{ fontSize: 20, color: "black", margin: "0" }}
                                >
                                  <ExpandMoreIcon />
                                </ExpandMore>
                              </Box>
                            </Box>
                          </Box>

                        </CardActions>
                        <Collapse key={i} in={expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded} timeout="auto" unmountOnExit>
                          <CardContent>
                            {x?.ContactInfo?.map((k: any, index2: number) => {
                              return (
                                <Box key={index2}
                                  sx={{
                                    display: 'flex',
                                    // alignItems: 'flex-start',
                                    flexDirection: 'column',
                                    // p: 0,
                                    // m: 0,
                                    // bgcolor: 'background.paper',
                                    // borderRadius: 1,
                                  }}
                                >
                                  <Grid key={index2} container spacing={0}>
                                    <Grid item xs={1} sx={{ pt: "12px", fontSize: "14px" }}>
                                      {index2 + 1 + ". "}
                                    </Grid>
                                    <Grid item xs={11}>
                                      <Box key={index2} sx={{ display: 'flex', mb: "10px", pt: "10px" }}>
                                        <TextField
                                          key={index2}
                                          required
                                          id="standard-basic"
                                          label="FullName"
                                          fullWidth
                                          inputProps={{
                                            step: 300, // 5 min
                                            style: {
                                              padding: "6px 0px 6px 6px",
                                              fontSize: "12px",
                                            },
                                          }
                                          }
                                          InputLabelProps={{ shrink: true }}

                                          value={k?.Guest?.Contact?.FullName as string || ''}
                                          onChange={e => {
                                            let obj = {
                                              ...k.Guest?.Contact,
                                              FullName: e.target.value
                                            }
                                            handleChange(obj, i, index2)
                                          }}
                                        />
                                      </Box>

                                      <Box sx={{ display: 'flex', pb: "10px" }}>
                                        <TextField
                                          key={index2}
                                          required
                                          id="standard-basic"
                                          label="Email"
                                          fullWidth
                                          inputProps={{
                                            step: 300, // 5 min
                                            style: {
                                              padding: "6px 0px 6px 6px",
                                              fontSize: "12px",
                                            },
                                          }
                                          }
                                          InputLabelProps={{ shrink: true }}
                                          value={k?.Guest?.Contact?.Email || ''}
                                          onChange={e => {
                                            let obj = {
                                              ...k.Guest?.Contact,
                                              Email: e.target.value
                                            }
                                            handleChange(obj, i, index2)
                                          }}
                                        />
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              )
                            })
                            }
                          </CardContent>
                        </Collapse>
                      </Card>
                    </div>

                  </>
                )
              })}

              <Grid
                item
                xs={1}
                style={{ justifyContent: 'center', display: 'flex' }}
              >
              </Grid>

            </div>
          </List>
        </Box>
      </MainWrapper>
      <Footer
        options={[
          {
            name: 'Confirm',
            onClick: () => onSubmit(),
            // boxShadow: "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",

          },
        ]}
      />
    </>


  );
}

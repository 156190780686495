import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  List,
  styled,
  TextField
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import CompanyLogo from '../../src/Components/Logo';
import {
  usePreRegBookingInfoQuery
} from "../generated/graphql";
import { MainWrapper } from "./MainWrapper";
import { Footer } from '../Components/Footer';
import PersonIcon from '@mui/icons-material/Person';
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


export default function BookingInfo() {
  const dateFormat = require("dateformat");
  const { state }: any = useLocation();
  const location = useLocation();
  const history = useNavigate();

  //to get hotelid and booking id from url
  const queryParameters = new URLSearchParams(window.location.search);
  const URLBookingID = queryParameters.get("BookingID") as string;
  const URLHotelID = queryParameters.get("HotelID") as string;



  const { data: { PreRegBookingInfo } = { PreRegBookingInfo: [] } } =
    usePreRegBookingInfoQuery({
      variables: {
        HotelID: state?.HotelID,
        BookingID: state?.BookingID,
      },
      fetchPolicy: 'no-cache',
    });
  const [chk, setChk] = useState({
    RoomType: "",
    ArrivalDate: "",
    DepartureDate: "",
  });



  let arr: any = []
  useEffect(() => {
    PreRegBookingInfo?.result?.map((t: any) => {
      arr.push({
        ID: t.RoomType,
        SharerNo: t.SharerNo,
        IsExpanded: false
      })
    })
    setExpanded(arr)
  }, [PreRegBookingInfo])
  const [expanded, setExpanded] = useState(arr)
  const handleExpandClick = (id: any) => {
    let NewArray = expanded.map((f: any) => {
      if (f.SharerNo === id) {
        return {
          ID: f.ID,
          IsExpanded: f.IsExpanded === true ? false : true,
          SharerNo: f.SharerNo
        }
      }
      else {
        return {
          ...f
        }
      }
    })
    setExpanded(NewArray);
  };

  let GuestArr: any = []

  const SortRegBySharerNo = PreRegBookingInfo?.result?.sort((a, b) => 
    a.Registrations[0]?.SharerNo - b.Registrations[0]?.SharerNo
  );

  useEffect(() => {
    SortRegBySharerNo?.map((t: any, i: number) => {
      GuestArr.push({
        ContactInfo: t.Registrations.map((y: any, i2: number) => {
          return {
            ...y,
            Order: i2 + 1
          }
        }),
        RoomType: t.RoomType,
        SharerNo:t.SharerNo
      })
    })
    setGuest(GuestArr)
  }, [PreRegBookingInfo])
  const [guest, setGuest] = useState(arr)

  //Add Guest
  const handlePlus = (i: any) => {
    let noguest = guest.find((gg: any) => gg.SharerNo === i)?.ContactInfo?.length
    const result = guest?.map(((g: any) => {
      if (g.SharerNo === i) {
        return {
          ContactInfo: [{
            Order: noguest + 1,
            Mode: 'add',
            Guest:
            {
              Contact: {
                Email: "",
                FullName: ""
              }
            }
          }, ...g.ContactInfo]
          ,
          RoomType: g.RoomType,
          SharerNo: g.SharerNo
        }
      }
      else {
        return {
          ...g
        }
      }
    }))
    setGuest(result.map((sort: any) => {
      return {
        ...sort,
        ContactInfo: sort?.ContactInfo?.sort((a: any, b: any) => a.Order - b.Order)
      }
    }))
  }

  //Remove Guest
  const handleMinus = (RoomType: any) => {
    const ContactInfoLength: number = guest?.find((y: any) => y?.RoomType === RoomType)?.ContactInfo?.length
    const UpdatedList = guest?.find((z: any) => z.RoomType === RoomType)?.ContactInfo.filter((item: any) => item.Order !== ContactInfoLength)
    const AllGuest = guest?.map((g: any) => {
      if (g.RoomType === RoomType) {
        return {
          ...g,
          ContactInfo: UpdatedList
        }
      }
      else {
        return {
          ...g
        }
      }

    })
    setGuest(AllGuest)
  }

  const GuestHandler = (Property: any, Value: any, ContactID: any, RoomType: any) => {

    const currentObj = guest?.find((x: any) => x.RoomType === RoomType)?.ContactInfo?.find((y: any) => y?.ContactID === ContactID)?.Guest?.Contact

    let objGuest = {
      ...currentObj,
      [Property]: Value
    }

    let NewArray = guest?.map((f: any) => {
      if (f.RoomType === RoomType?.ContactInfo?.map((g: any) => g?.ContactID === ContactID)?.Guest?.Contact) {
        return {
          ...f,
          ContactInfo: objGuest
        }
      }
      else {
        return {
          ...f
        }
      }
    })

  }

  const Guest = state?.Guest === 1 ? "Guest" : "Guests"
  return (
    <>
      <MainWrapper>

        <Box
          sx={{ p: 2 }}
          justifyContent="center"
          alignItems="center"
          width={"400px"}
          margin="auto"
          bgcolor={"#F7FAF7"}
        >
          {" "}
          <Grid container spacing={2}>
            <Grid item xs={16}>
              <Box display="flex" mb="12px" alignItems="center" justifyContent="center">
                <Box display="flex" mb="12px" alignItems="center" justifyContent="center">
                  <img
                    src={state?.HotelDetails?.LogoImage?.ImageURL as any}
                    alt={`IMG`}
                    loading="lazy"
                    width="180px"
                  />
                </Box>
              </Box>
              <Typography fontSize="17px" fontWeight={600} mb="8px" align="center">
                Pre Registration Successful
              </Typography>
              <Typography fontSize="13px" fontWeight={500} mb="12px" color="black" align="center">
                Thank you
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" height={20} mb="12px">
            <Typography fontSize="14px" fontWeight={600} >
              Booking Info
            </Typography>
          </Box>

          <Card
            sx={{
              mb: "14px",
              borderRadius: "4px",
              boxShadow:
                "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
            </Box>
            <CardContent
              sx={{
                ":last-child": { p: "10px" },
              }}
            >
              <Box width="100%" display="flex" mb="10px" >
                <Box width="50%" display="flex">
                  <Typography fontSize="14px" fontWeight={500} color="black" align="left" >
                    Booking No.
                  </Typography>
                </Box>
                <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                  <Typography fontSize="14px" fontWeight={500} color="secondary">
                    {PreRegBookingInfo?.BookingNo}
                  </Typography>
                </Box>
              </Box>
              <Box width="100%" display="flex">
                <Box width="50%" display="flex" alignItems={"center"}>
                  <Typography fontSize="14px" fontWeight={500} color="black" >
                    No of Room(s)
                  </Typography>
                </Box>
                <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                  <Typography fontSize="14px" fontWeight={500} color="secondary">
                    {PreRegBookingInfo?.result?.length}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>


          <div className="rm-padding p-10 ">
            <List className="core-list reset-listitem ">
              {console.log(expanded, state, guest, "expand")}
              {guest?.map((x: any, i: any) => {
                return (
                  <>
                    <Card
                      sx={{
                        mb: "14px",
                        borderRadius: "4px",
                        boxShadow:
                          "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
                      }}
                    >
                      <CardActions disableSpacing >
                        <Box width="100%" display="flex" sx={{ flexDirection: 'column', margin: "5px" }}>
                          <Typography fontSize="17px" fontWeight={600} mb="8px" >
                            Registration Info
                          </Typography>
                        </Box>
                        <Box width="100%" display="flex" sx={{ justifyContent: 'flex-end' }}>
                          <ExpandMore
                            expand={expanded?.IsExpanded}
                            onClick={(SharerNo) => handleExpandClick(x.SharerNo)}

                          >
                            <ExpandMoreIcon />
                          </ExpandMore>
                        </Box>

                      </CardActions>
                      <Collapse key={i} in={expanded?.find((y: any) => y.SharerNo === x.SharerNo)?.IsExpanded} timeout="auto" unmountOnExit>
                        <CardContent>
                          <Typography fontSize="14px" fontWeight={600} color="black" align="left" >
                            {"Room " + Number(i + 1) + " - " + x.ContactInfo[0]?.RoomType?.Description}
                          </Typography>
                          <Typography fontSize="14px" fontWeight={600} color="black" align="left" >
                            {dateFormat(x.ContactInfo[0]?.ArrivalDate, "dd mmm yyyy")}  -  {dateFormat(x.ContactInfo[0]?.DepartureDate, "dd mmm yyyy")}
                          </Typography>
                          <Typography
                            style={{ display: "flex", alignItems: "center" }}
                            fontSize="14px"
                            fontWeight={600}
                            color="black"
                            align="left"
                          >
                            <PersonIcon sx={{ fontSize: 20, color: "black", marginRight: "5px" }} />
                            {state?.Guest + " " + Guest}
                          </Typography>

                          {x?.ContactInfo?.map((k: any, index2: number) => {
                            return (
                              <Box key={index2}
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',

                                }}
                              >
                                <Grid container spacing={0}>
                                  <Grid item xs={1} sx={{ pt: "15px", fontSize: "14px" }}>
                                    {index2 + 1 + ". "}
                                  </Grid>
                                  <Grid item xs={11}>
                                    <Box sx={{ display: 'flex', pt: "15px" }}>
                                      <Typography fontSize="13px" fontWeight={600} color="black" align="left" >
                                        Guest Name
                                      </Typography>

                                    </Box>
                                    <Box sx={{ display: 'flex', mb: "5px", pt: "4px"}}>
                                      <Typography fontSize="15px" fontWeight={500} color="black" align="left" >
                                        {k?.Guest?.Contact?.FullName || ''}
                                      </Typography>

                                    </Box>

                                    <Box sx={{ display: 'flex' }}>
                                      <Typography fontSize="13px" fontWeight={600} color="black" align="left" >
                                        Email
                                      </Typography>

                                    </Box>
                                    <Box sx={{ display: 'flex', mb: "10px", pt: "4px" }}>
                                      <Typography fontSize="15px" fontWeight={500} color="black" align="left" >
                                        {k?.Guest?.Contact?.Email || ''}
                                      </Typography>

                                    </Box>



                                  </Grid>
                                </Grid>
                              </Box>
                            )
                          })
                          }
                        </CardContent>
                      </Collapse>
                    </Card>
                  </>
                )
              })}
              <Grid
                item
                xs={1}
                style={{ justifyContent: 'center', display: 'flex' }}
              >
              </Grid>
            </List>
          </div>
          <Typography fontSize="14px" fontWeight={500} mb="10px" justifyContent={'center'} display={'flex'}>
            * An email will be forwarded to you soon for the precheckin process.
          </Typography>
        </Box>
      </MainWrapper>
    </>

  );
}

export const ValidateDob = (ICno) => {

    let icno = ICno

    var Year = icno.substring(0, 2);
    var Month = icno.substring(2, 4)
    var Day = icno.substring(4, 6)
    var cutoff = (new Date()).getFullYear() - 2000
    var dob = (Year > cutoff ? '19' : '20') + Year + '-' + Month + '-' + Day;

    let newdatedob = new Date(dob)

    return newdatedob;
}
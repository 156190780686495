import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  List,
  styled
} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from '@mui/material/CardActions';
import CardContent from "@mui/material/CardContent";
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import QRCode from "qrcode.react";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { MainWrapper } from "./MainWrapper";
import { useHotelDetailsQuery, usePreRegBookingInfoQuery } from '../generated/graphql';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RegistrationInfo() {
  const dateFormat = require("dateformat");
  const { state }: any = useLocation();
  const location = useLocation();
  const history = useNavigate();
  const regInfo = state?.state

  const {
    data: { HotelDetails } = { HotelDetails: null },
  } = useHotelDetailsQuery({
    variables: {
      HotelID: state?.PreRegBookingInfo?.Guest?.HotelID,
    },
    fetchPolicy: 'no-cache',
  })

  const
    {
      data:
      { PreRegBookingInfo } = { PreRegBookingInfo: [] }
    } = usePreRegBookingInfoQuery({
      variables: {
        HotelID: state?.PreRegBookingInfo?.Booking?.HotelID,
        BookingID: state?.PreRegBookingInfo?.BookingID,
        RegistrationID: state?.PreRegBookingInfo?.ID,
        QR: 'true'
      },
      fetchPolicy: 'no-cache'
    })
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded); // Toggle the expanded state
  };

  //QR
  const QrCodeRef = useRef<HTMLDivElement>(null);

  const QRContent = (
    <div
      ref={QrCodeRef}
      className="content-container "
      style={{ justifyContent: 'center', marginBottom: '12px' }}
    >
      <QRCode
        renderAs={'canvas'}
        value={`${state?.PreRegBookingInfo?.Booking?.HotelID}/${state?.PreRegBookingInfo?.BookingID}/${state?.PreRegBookingInfo?.ID}`}
      />
    </div>
  )
  const handleDownloadClick = () => {
    const qrCodeElement = QrCodeRef.current;
    if (qrCodeElement) {
      const canvas = qrCodeElement.querySelector('canvas');
      if (canvas) {
        const desiredWidth = 600; // Specify the desired width for the resized QR code
        const scaleFactor = desiredWidth / canvas.width;
        const desiredHeight = canvas.height * scaleFactor;

        const borderWidth = 10; // Specify the width of the white border
        const canvasWidthWithBorder = desiredWidth + 2 * borderWidth;
        const canvasHeightWithBorder = desiredHeight + 2 * borderWidth;

        const tempCanvas = document.createElement('canvas');
        const tempContext = tempCanvas.getContext('2d') as CanvasRenderingContext2D;

        tempCanvas.width = canvasWidthWithBorder;
        tempCanvas.height = canvasHeightWithBorder;

        tempContext.fillStyle = 'white';
        tempContext.fillRect(0, 0, canvasWidthWithBorder, canvasHeightWithBorder);

        const x = borderWidth;
        const y = borderWidth;

        tempContext.drawImage(canvas, x, y, desiredWidth, desiredHeight);

        const downloadLink = document.createElement('a');
        downloadLink.href = tempCanvas.toDataURL('image/png');
        downloadLink.download = 'hotelx_qr.png';
        downloadLink.click();
      }
    }
  };

  const styles = {
    link: {
      fontSize: '12px',
      fontWeight: 400,
      color: "#f5ab35",
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  };

  const styleDownload = {
    link: {
      fontSize: '12px',
      fontWeight: 400,
      color: 'blue',
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  };

  const textStyle: React.CSSProperties = {
    fontSize: '17px',
    fontWeight: 600,
    marginBottom: '8px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };
  const textStyle1: React.CSSProperties = {
    fontSize: '10px',
    fontWeight: 500,
    justifyContent: 'center',
    display: 'flex',
    flexWrap: 'wrap',
  };

  const strongStyle: React.CSSProperties = {
    margin: '0 4px',
  };

  const [openTerm, setOpenTerm] = useState(false);

  const handleOpenTerm = () => {
    setOpenTerm(true);
  };

  const handleCloseTerm = () => {
    setOpenTerm(false);
  };

  const [openPdpa, setOpenPdpa] = useState(false);

  const handleOpenPdpa = () => {
    setOpenPdpa(true);
  };

  const handleClosePdpa = () => {
    setOpenPdpa(false);
  };

  const Signature = PreRegBookingInfo?.Gallery?.find(x => x.ID === PreRegBookingInfo?.result?.map(x => x?.Registrations?.find(y => y?.ID === state?.PreRegBookingInfo?.ID))[0]?.Signature)?.ImageURL
  const FrontPhoto = PreRegBookingInfo?.Gallery?.find(x => x.ID === PreRegBookingInfo?.result?.map(x => x?.Registrations?.find(y => y?.ID === state?.PreRegBookingInfo?.ID))[0]?.Guest?.IdPicture)?.ImageURL
  const BackPhoto = PreRegBookingInfo?.Gallery?.find(x => x.ID === PreRegBookingInfo?.result?.map(x => x?.Registrations?.find(y => y?.ID === state?.PreRegBookingInfo?.ID))[0]?.Guest?.IcBackPicture)?.ImageURL

  console.log("lst", state, FrontPhoto)
  return (
    <>
      <MainWrapper>
        <Box
          sx={{ p: 2 }}
          justifyContent="center"
          alignItems="center"
          width={"400px"}
          margin="auto"
          bgcolor={"#F7FAF7"}
        >
          <Grid container spacing={2}>
            <Grid item xs={16}>
              <Box display="flex" mb="12px" alignItems="center" justifyContent="center">
                <Box display="flex" mb="12px" alignItems="center" justifyContent="center">
                  <img
                    src={HotelDetails?.LogoImage?.ImageURL as any}
                    alt={`IMG`}
                    loading="lazy"
                    width="180px"
                  />
                </Box>
              </Box>
              <p style={textStyle}>
                Congratulation Successful Pre Check-In
              </p>
              <Typography fontSize="13px" fontWeight={500} mb="12px" color="black" align="center">
                See you soon!
              </Typography>
            </Grid>
          </Grid>
          <Box display="flex" height={20} mb="12px">
            <Typography fontSize="14px" fontWeight={600} >
              Registration Info
            </Typography>
          </Box>


          <div className="rm-padding p-10 ">
            <List className="core-list reset-listitem ">
              <>
                <Card
                  sx={{
                    mb: "14px",
                    borderRadius: "4px",
                    boxShadow:
                      "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
                  }}
                >
                  <CardActions disableSpacing >
                    <Box width="100%" display="flex" sx={{ flexDirection: 'column', margin: "5px" }}>
                      <Typography fontSize="12px" fontWeight={400} color="black" align="left" >
                        Booking No: {state?.PreRegBookingInfo?.Booking?.BookingNo}
                      </Typography>
                      <Typography fontSize="12px" fontWeight={400} color="black" align="left" >
                        {state?.PreRegBookingInfo?.Guest?.Contact?.FullName}
                      </Typography>
                      <Typography fontSize="12px" fontWeight={400} color="black" align="left" >
                        {state?.PreRegBookingInfo?.Guest?.Contact?.Email ? state?.PreRegBookingInfo?.Guest?.Contact?.Email : ""}
                      </Typography>
                    </Box>
                    <Box width="100%" display="flex" sx={{ justifyContent: 'flex-end' }}>
                      <Typography fontSize="12px" fontWeight={400} color="black" align="right" >
                        {dateFormat(state?.PreRegBookingInfo?.ArrivalDate, "dd/mm/yyyy")} - {dateFormat(state?.PreRegBookingInfo?.DepartureDate, "dd/mm/yyyy")}
                      </Typography>
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </Box>

                  </CardActions>
                </Card>

                <Card
                  sx={{
                    mb: "14px",
                    borderRadius: "4px",
                    boxShadow:
                      "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
                  }}
                >
                  <Collapse in={expanded === true} timeout="auto" unmountOnExit>
                    <CardContent >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',

                        }}
                      >
                        <Grid container spacing={0}>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={600} color="black"  >
                              {state?.PreRegBookingInfo?.Guest?.Contact?.Nationality === "Malaysian" ? "ID No. (NRIC/MyKAD)" : "Passport No."}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={600} color="black"  >
                              Date of Birth
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {regInfo?.NRICNo === "" ? state?.PreRegBookingInfo?.Guest?.Contact?.NRIC : regInfo?.NRICNo}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {dateFormat(regInfo?.DateofBirth, "dd/mm/yyyy") === dateFormat(new Date, "dd/mm/yyyy") ? dateFormat(state?.PreRegBookingInfo?.Guest?.Contact?.BirthDate, "dd/mm/yyyy") : dateFormat(regInfo?.DateofBirth, "dd/mm/yyyy")}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"}>
                              Mobile No.
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {regInfo?.MobileNo || state?.PreRegBookingInfo?.Guest?.Contact?.MobileNo}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"}>
                              Address
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {regInfo?.Address || state?.PreRegBookingInfo?.Guest?.Contact?.Address?.address}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"}>
                              City
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"}>
                              Postcode
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {regInfo?.City || state?.PreRegBookingInfo?.Guest?.Contact?.Address?.city}
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {regInfo?.PostCode || state?.PreRegBookingInfo?.Guest?.Contact?.Address?.postCode}
                            </Typography>
                          </Grid>

                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"}>
                              State
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {regInfo?.State || state?.PreRegBookingInfo?.Guest?.Contact?.Address?.state}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"}  >
                              Nationality
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"}  >
                              Country
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {state?.PreRegBookingInfo?.Guest?.Contact?.Nationality}
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {state?.PreRegBookingInfo?.Guest?.Contact?.Address?.country}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"}>
                              Special Request
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={400} color="black"  >
                              {regInfo?.SpecialRequest || state?.PreRegBookingInfo?.SpecialRequest}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography fontSize="12px" fontWeight={600} color="black" marginTop={"10px"}>
                              Attachment of IC/Passport
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={600} color="black"  >
                              Front
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography fontSize="12px" fontWeight={600} color="black"  >
                              Back
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <img
                              src={FrontPhoto || state?.uploadFile?.uploadFileFront}
                              alt={`IMG`}
                              loading="lazy"
                              width="60%"
                              height="60%"
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <img
                              src={BackPhoto || state?.uploadFile?.uploadFileBack}
                              alt={`IMG`}
                              loading="lazy"
                              width="60%"
                              height="60%"
                            />
                          </Grid>

                          <Grid item xs={12} paddingBottom={"10px"}>
                            <FormControlLabel
                              control={<Checkbox name="checkedB"
                                color="primary"
                                checked={true}
                                readOnly />}
                              label={<Typography fontSize="12px" fontWeight={400} color="black" align="left" >
                                I acknowledge and accept the hotel <span style={styles.link} onClick={handleOpenTerm} >Terms and Condition</span>
                              </Typography>}
                            />

                            <FormControlLabel
                              control={<Checkbox name="checkedB"
                                color="primary"
                                checked={true}
                                readOnly />}
                              label={<Typography fontSize="12px" fontWeight={400} color="black" align="left" >
                                I acknowledge and accept the hotel <span style={styles.link} onClick={handleOpenPdpa} >PDPA</span>
                              </Typography>}
                            />
                          </Grid>
                          <Box
                            sx={{
                              width: 320, height: 100,
                              border: '3px solid', borderColor: 'rgb(220,220,220)', borderRadius: '15px'
                            }}
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <img
                              src={Signature || regInfo?.SignatureUrl}
                              alt={`IMG`}
                              loading="lazy"

                            />
                          </Box>

                        </Grid>
                      </Box>
                      {/* )
                            })
                            } */}
                    </CardContent>
                  </Collapse>
                </Card>

                <Typography fontSize="14px" fontWeight={600} mb="10px">
                  My QR
                </Typography>

                <Card
                  sx={{
                    mb: "14px",
                    borderRadius: "4px",
                    boxShadow:
                      "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
                  }}
                >

                  <CardContent
                    sx={{
                      ":last-child": { p: "20px" },
                    }}
                  >
                    <Box display="flex" alignItems="center" justifyContent="center">
                      {QRContent}
                    </Box>
                    <Box width="100%" display="flex" sx={{ justifyContent: 'flex-end' }}>
                      <Typography fontSize="12px" fontWeight={400} color="black" align="right">
                        <span style={styleDownload.link} onClick={handleDownloadClick}>
                          Download
                        </span>
                      </Typography>
                    </Box>


                  </CardContent>

                </Card>
              </>
              {/* ) */}
              {/* })} */}
              <Grid
                item
                xs={1}
                style={{ justifyContent: 'center', display: 'flex' }}
              >
              </Grid>
            </List>
          </div>
        </Box>
        <Dialog open={openTerm} onClose={handleCloseTerm}>
          <DialogTitle sx={{ fontWeight: "bold", color: "#f5ab35", backgroundColor: "#fff5e7" }}>
            Terms and Condition
          </DialogTitle>
          <DialogContent>
            {HotelDetails?.RegCardTnC ? (
              <div dangerouslySetInnerHTML={{ __html: HotelDetails.RegCardTnC }} />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseTerm}
              sx={{
                backgroundColor: "#f5ab35",
                "&:hover": {
                  backgroundColor: "#f5ab35", // Keep the same background color on hover
                },
                "&:active": {
                  backgroundColor: "#f5ab35", // Keep the same background color when clicked
                },
              }}
            >            <Typography style={{ fontWeight: "bold", color: "#ffffff" }}>
                Close
              </Typography>

            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={openPdpa} onClose={handleClosePdpa}>
          <DialogTitle sx={{ fontWeight: "bold", color: "#f5ab35", backgroundColor: "#fff5e7" }}>
            PDPA
          </DialogTitle>
          <DialogContent>
            {HotelDetails?.PDPA ? (
              <div dangerouslySetInnerHTML={{ __html: HotelDetails.PDPA }} />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClosePdpa}
              sx={{
                backgroundColor: "#f5ab35",
                "&:hover": {
                  backgroundColor: "#f5ab35", // Keep the same background color on hover
                },
                "&:active": {
                  backgroundColor: "#f5ab35", // Keep the same background color when clicked
                },
              }}
            >
              <Typography style={{ fontWeight: "bold", color: "#ffffff" }}>
                Close
              </Typography>

            </Button>
          </DialogActions>
        </Dialog>
      </MainWrapper>
      <Typography style={textStyle1}>
        * Please download <strong style={strongStyle}> 'My QR'</strong>  into your photo gallery as your guest identity.
      </Typography>
      <Typography style={textStyle1}>
        For express check-in scan <strong style={strongStyle}>'My QR'</strong> at the front desk upon your arrival.
      </Typography>
    </>

  );
}

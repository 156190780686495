
export function validatePassword(pswrd: any) {
    var p = pswrd
    const passValidate: string[] = [];

    if (p.length < 8) {
        passValidate.push("• Password must be at least 8 characters");
    }
    if (p.match(/[a-z]/) === null) {
        passValidate.push("• Password must contain at least one lowercase character");
    }
    if (p.match(/[A-Z]/) === null) {
        passValidate.push("• Password must contain at least one uppercase character");
    }
    if (p.match(/[0-9]/) === null) {
        passValidate.push("• Your password must contain at least one number");
    }
    if (p.match(/[ `!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/) === null) {
        passValidate.push("• Your password must contain at least one symbol");
    }
    return passValidate;
}
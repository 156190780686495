import { Button } from '@mui/material'
import React from 'react'
interface IFooterProps {
  options?: any
  chipLabel?: string
  value?: string | number
  single?: Boolean
  isHidden?: boolean
  totalAmt?: boolean
  extraNote?: string
}
export const Footer = (props: IFooterProps) => {
  const { chipLabel, options, value, isHidden, totalAmt, extraNote } = props
  let single: boolean = false

  if (options?.length === 1) {
    single = true
  }

  return (
    <div style={{ position: 'fixed', bottom: '0', right: '0', width: '100%', textAlign: 'center', paddingTop: '15px', paddingBottom: '15px', backgroundColor: '#f5f5f5' }} id="footer">
      {chipLabel && (
        <div className="footer-label">
          <div className="chip-label">{chipLabel}</div>

          <span className="label-value">{value}</span>
        </div>
      )}
      {/* {totalAmt && (
        <div
          className="footer-label"
          style={{
            background: '#faf2e8',
          }}
        >
          <div className="xsTitle">
            Total: <span className="color-primary-orange">{value}</span>
          </div>
        </div>
      )} */}
      {/* {extraNote && (
        <div
          className="footer-label"
          style={{
            background: '#FE7540',
          }}
        >
          <div className="desc text-noflow" style={{ textAlign: 'center' }}>
            <span style={{ color: 'red' }}>{extraNote}</span>
          </div>
        </div>
      )} */}
      {isHidden ? null : (
        <div className="footer-btn">
          {options.map((el: any, index: any) => (
            <Button
              key={index}
              type="submit"
              // color={el.color}
              sx={{
                backgroundColor: "#f5ab35",
                "&:hover": {
                  backgroundColor: "#f5ab35", // Keep the same background color on hover
                },
                "&:active": {
                  backgroundColor: "#f5ab35", // Keep the same background color when clicked
                }, color: "#ffffff"
              }}
              style={{ minWidth: options.length > 2 ? '50px' : '126px' }}
              variant="contained"
              className={single ? 'single' : ''}
              onClick={el.onClick}
              disabled={el.disabled}
              {...el.props}
            >
              {el.name}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}

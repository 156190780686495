import AddBoxIcon from '@mui/icons-material/AddBox';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Snackbar,
  TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";
import { MuiTelInput } from "mui-tel-input";
import React, { Reducer, useEffect, useReducer, useRef, useState } from "react";
import { PatternFormat } from 'react-number-format';
import { useLocation, useNavigate } from "react-router";
import SignaturePad from 'react-signature-canvas';
import { Footer } from "../Components/Footer";
import { b64toBlob, OCRhelper } from "../Components/Hooks/OCRhelper";
import CompanyLogo from '../Components/Logo';
import {
  Country,
  Nationality,
  useHotelDetailsQuery,
  usePreCheckInInsertMutation,
  usePreRegBookingInfoLazyQuery,
  usePreRegBookingInfoQuery,
  State,
} from "../generated/graphql";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { useSnackBar } from '../../src/Components/Hooks/useSnackBar'
import SnackBarMsg from '../Components/SnackBar/SnackBarMsg';
import { ValidateDob } from '../ValidateDob';

interface Props {
  NRICNo: any
  DateofBirth?: Date
  MobileNo?: string
  Nationality?: string
  Country?: string
  IcPhotoFront?: any
  icphotoFront?: any
  IcPhotoBack?: any
  icphotoBack?: any
  IsTerm: boolean
  IsPDPA: boolean
  IsSignature: boolean
  SignatureUrl?: any
  blob: null
  birth?: any
  Address:string
  PostCode:string
  City:string
  State:string
  SpecialRequest:string
}

interface IAction<TType = string, TPayload = any> {
  type: TType;
  payload: TPayload;
}

let NationalityEnum: string[] = Object.values(Nationality).map((i) => {
  return i.replace(/_/g, " ");
});
let CountryEnum: any = Object.values(Country).map((i) => {
  return i.replace(/_/g, " ");
});

export const PreCheckInForm = () => {

  const queryParameters = new URLSearchParams(window.location.search);
  const URLBookingID = queryParameters.get("Booking") as string;
  const URLHotelID = queryParameters.get("Hotel") as string;
  const URLRegistrationID = queryParameters.get("Registration") as string;
  const dateFormat = require('dateformat')
  const location = useLocation();
  const localstate: any = location.state;
  const navigate = useNavigate();
  const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar()

  const initialState: Props = {
    NRICNo: '',
    DateofBirth: new Date(),
    MobileNo: '',
    Nationality: 'Malaysian',
    Country: 'Malaysia',
    IcPhotoFront: '',
    icphotoFront: '',
    IcPhotoBack: '',
    icphotoBack: '',
    IsTerm: false,
    IsPDPA: false,
    IsSignature: false,
    SignatureUrl: null,
    blob: null,
    birth: new Date(),
    Address:'',
    PostCode:'',
    City:'',
    State:'',
    SpecialRequest:'',

  }
  let StateEnum: any = Object.values(State).map(i => {
    return i.replace(/_/g, ' ')
  })
  const reducer: Reducer<Props, IAction> = (state, action) => {
    switch (action.type) {
      case 'reset':
        return initialState
      default:
        return { ...state, [action.type]: action.payload }
    }
  }

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#f5ab35', // Set your desired color here
      },
    },

  });

  const [uploadFile, setUploadFile] = useState({
    uploadFileFront: "",
    uploadFileBack: "",
  })

  const [prevFile, setPrevFile] = useState({
    IcFront: "",
    IcBack: "",
  })


  const {
    data: { HotelDetails } = { HotelDetails: null },
  } = useHotelDetailsQuery({
    variables: {
      HotelID: URLHotelID,
    },
    fetchPolicy: 'no-cache',
  })

  const [
    PreCheckInInsert,
    { loading: loadingInsert }
  ] = usePreCheckInInsertMutation({})

  const [
    PreRegInfo,
    {
      loading: Loadings,
      data: { PreRegBookingInfo } = {
        PreRegBookingInfo: []
      },
    },
  ] = usePreRegBookingInfoLazyQuery({

    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (URLHotelID !== null && URLBookingID !== null) {
      PreRegInfo(
        {
          variables: {
            HotelID: URLHotelID,
            BookingID: URLBookingID,
            RegistrationID: URLRegistrationID
          },
        }
      ).then(i => {
        console.log(i, "try")
        dispatch({ 
          type: 'NRICNo', 
          payload: i.data?.PreRegBookingInfo?.Guest?.Contact?.NRIC
            ? i.data?.PreRegBookingInfo?.Guest?.Contact?.NRIC
            : '' 
        })
        dispatch({ 
          type: 'Address', 
          payload: i.data?.PreRegBookingInfo?.Guest?.Contact?.Address?.address
            ? i.data?.PreRegBookingInfo?.Guest?.Contact?.Address?.address
            : '' 
        })
        dispatch({ 
          type: 'City', 
          payload: i.data?.PreRegBookingInfo?.Guest?.Contact?.Address?.city
            ? i.data?.PreRegBookingInfo?.Guest?.Contact?.Address?.city
            : '' 
        })
        dispatch({ 
          type: 'PostCode', 
          payload: i.data?.PreRegBookingInfo?.Guest?.Contact?.Address?.postCode
            ? i.data?.PreRegBookingInfo?.Guest?.Contact?.Address?.postCode
            : '' 
        })
        dispatch({ 
          type: 'State', 
          payload: i.data?.PreRegBookingInfo?.Guest?.Contact?.Address?.state
            ? i.data?.PreRegBookingInfo?.Guest?.Contact?.Address?.state
            : '' 
        })
        dispatch({ 
          type: 'DateofBirth', 
          payload: i.data?.PreRegBookingInfo?.Guest?.Contact?.BirthDate 
            ? new Date(i.data?.PreRegBookingInfo?.Guest?.Contact?.BirthDate) 
            : new Date() 
        })
        dispatch({ 
          type: 'MobileNo', 
          payload: i.data?.PreRegBookingInfo?.Guest?.Contact?.MobileNo 
            ? `${i.data?.PreRegBookingInfo?.Guest?.Contact?.MobileNo}`
            : '' 
        })
        dispatch({ 
          type: 'SpecialRequest', 
          payload: i.data?.PreRegBookingInfo?.SpecialRequest 
            ? `${i.data?.PreRegBookingInfo?.SpecialRequest}`
            : '' 
        })
        dispatch({
          type: 'IcPhotoFront',
          payload: i.data?.PreRegBookingInfo?.Guest?.IdPicture 
            ? i.data?.PreRegBookingInfo?.Guest?.IdPicture
            : ''
        })
        dispatch({
          type: 'IcPhotoBack',
          payload: i.data?.PreRegBookingInfo?.Guest?.IcBackPicture 
            ? i.data?.PreRegBookingInfo?.Guest?.IcBackPicture
            : ''
        })
        setUploadFile({
          uploadFileFront: i.data?.PreRegBookingInfo?.Guest?.ICGallery?.ImageURL,
          uploadFileBack: i.data?.PreRegBookingInfo?.Guest?.ICBackGallery?.ImageURL
        })
        setPrevFile({
          IcFront: i.data?.PreRegBookingInfo?.Guest?.ICGallery?.ImageURL,
          IcBack: i.data?.PreRegBookingInfo?.Guest?.ICBackGallery?.ImageURL
        })
      })
    }
  }, [URLHotelID, URLBookingID])

  const [check, setCheck] = useState(false)
  const [checkPdpa, setCheckPdpa] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const dates = dateFormat(new Date(), "dd mmm yyyy") > dateFormat(PreRegBookingInfo?.ArrivalDate, "dd mmm yyyy")
  const status = !Loadings && PreRegBookingInfo?.Booking?.BookingStatus !== 'Booked'

  let signCanvas: any = useRef(null)
  let inputFile: any = useRef(null)
  let attachmentFiles: any = [];

  const handleTrimSignature = () => {
    if (signCanvas.current && state.IsSignature) {
      var contentType = "png"
      let signUrl = signCanvas.current?.getTrimmedCanvas().toDataURL('image/png')
      var img = signUrl.slice(22)
      const blob = b64toBlob(img, contentType, 512)
      blob['name'] = 'Signature' + '.png'
      blob['lastModified'] = Date.UTC(
        dateFormat(new Date(), 'yyyy'),
        dateFormat(new Date(), 'mm'),
        dateFormat(new Date(), 'dd')
      )
      blob['lastModifiedDate'] = new Date()
      dispatch({
        type: 'blob',
        payload: blob,
      })
      dispatch({
        type: 'SignatureUrl',
        payload: signCanvas.current?.getTrimmedCanvas().toDataURL('image/png')
      })
      dispatch({ type: 'IsSignature', payload: true })
      console.log(blob, 'blobbb')

      return { blob, signUrl };
    }
  }

  const clear = () => {
    signCanvas.current?.clear()
    dispatch({ type: 'IsSignature', payload: false })
    dispatch({ type: 'SignatureUrl', payload: null })
  }

  const handleUploadFile = async (mode, e) => {
    const file = e.target.files[0];

    // Check if the file is not an image
    if (!file.type.startsWith('image/')) {
      snackFunc('Invalid File Format', false);
      return;
    }

    let OCRhelper2 = await OCRhelper(file);
    console.log("OCRhelper2", OCRhelper2.Blob);
    let ImageFile = URL.createObjectURL(file);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as any;

    const watermarkText = 'Hotelx';

    const image = new Image();
    image.src = ImageFile;
    image.onload = async () => {
      canvas.width = image.width;
      canvas.height = image.height;

      // Draw the image on the canvas
      ctx.drawImage(image, 0, 0);

      // Add the watermark
      const fontSize = Math.max(canvas.width / 5, 84); // Adjust the font size based on the canvas width
      ctx.font = `bold ${fontSize}px Arial`;
      ctx.fillStyle = 'black'; // Set the watermark color to black
      ctx.textAlign = 'center'; // Center align the text
      ctx.textBaseline = 'middle'; // Center align vertically
      ctx.fillText(watermarkText, canvas.width / 2, canvas.height / 2); // Center the watermark text

      // Convert the canvas content to a data URL
      const watermarkedImage = canvas.toDataURL();

      if (mode === "Front") {
        setUploadFile({
          ...uploadFile,
          uploadFileFront: OCRhelper2.picture,
        });
        await dispatch({
          type: 'IcPhotoFront',
          payload: OCRhelper2.Blob,
        });
        attachmentFiles.push({
          Type: "IcPhotoFront",
          ImgUrl: state?.IcPhotoFront
        });
        setPrevFile({
          ...prevFile,
          IcFront: '',
        });
      } else if (mode === "Back") {
        setUploadFile({
          ...uploadFile,
          uploadFileBack: OCRhelper2.picture,
        });
        await dispatch({
          type: 'IcPhotoBack',
          payload: OCRhelper2.Blob,
        });
        attachmentFiles.push({
          Type: "IcPhotoBack",
          ImgUrl: state?.IcPhotoBack
        });
        setPrevFile({
          ...prevFile,
          IcBack: '',
        });
      }

      // You can use the watermarkedImage data URL as needed
      // console.log("Watermarked Image Data URL:", watermarkedImage);
    };
  };

  // const inputFile = useRef(null);

  const onSubmit = () => {
    console.log(new Date(state?.DateofBirth!), 'dd')
    PreCheckInInsert({
      variables: {
        PreCheckInInput: {
          NRICNo: state?.NRICNo || "",
          DateofBirth: state?.DateofBirth,
          // DateofBirth: new Date(state?.DateofBirth!),
          MobileNo: state?.MobileNo || "",
          Nationality: state?.Nationality as any,
          Address: {
            address:state.Address,
            country: state?.Country,
            state:state.State,
            city:state.City,
            postCode:state.PostCode
          },
         SpecialRequest: state?.SpecialRequest || ""

        },
        BookingID: URLBookingID,
        RegistrationID: URLRegistrationID,
        IcPhotoFront: prevFile?.IcFront ? null : state?.IcPhotoFront,
        IcPhotoBack: prevFile?.IcBack ? null : state?.IcPhotoBack,
        Signature: handleTrimSignature()?.blob,
      }
    })
    navigate("/pre-checkin-form/view", { state: { state: { ...state, SignatureUrl: handleTrimSignature()?.signUrl }, uploadFile, PreRegBookingInfo } })
  }
  console.log(state, 'state')
  console.log(uploadFile, 'uploadFile')
  const [openTerm, setOpenTerm] = useState(false);

  const handleOpenTerm = () => {
    setOpenTerm(true);
  };

  const handleCloseTerm = () => {
    setOpenTerm(false);
  };

  const [openPdpa, setOpenPdpa] = useState(false);

  const handleOpenPdpa = () => {
    setOpenPdpa(true);
  };

  const handleClosePdpa = () => {
    setOpenPdpa(false);
  };

  if (!URLHotelID || !URLBookingID || !URLRegistrationID || !Loadings && dates || status) {
    navigate("/missing-booking-info");
  }
  if (PreRegBookingInfo?.IsPreCheckIn === true){
    navigate("/pre-checkin-form/view", { state: { state: { ...state, SignatureUrl: handleTrimSignature()?.signUrl }, uploadFile, PreRegBookingInfo } })

  }

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Box
        sx={{ p: 2 }}
        width={"410px"}
        margin="auto"
        bgcolor={"#F7FAF7"}
      >
        {" "}
        <Grid container spacing={2}>
          <Grid item xs={16}>
            <Box display="flex" mb="12px" alignItems="center" justifyContent="center">
              <Box display="flex" mb="12px" alignItems="center" justifyContent="center">
                <img
                  src={HotelDetails?.LogoImage?.ImageURL as any}
                  alt={`IMG`}
                  loading="lazy"
                  width="180px"
                />
              </Box>
            </Box>
            <FormGroup sx={{ mb: "3px" }} style={{ alignContent: "center" }}>
              <Typography fontSize="17px" fontWeight={600}>
                Hi{" "}
                <Typography
                  color="blue"
                  fontSize="17px"
                  fontWeight={600}
                  component="span"
                >
                  {PreRegBookingInfo?.Guest?.Contact?.FullName}!
                </Typography>
              </Typography>
            </FormGroup>
            <Typography fontSize="17px" fontWeight={600} mb="1px" align="center">
              Welcome to {HotelDetails?.HotelName}
            </Typography>
            <Typography fontSize="14px" fontWeight={500} mb="12px" color="black" align="center">
              Kindly complete the information below :-
            </Typography>
          </Grid>
        </Grid>
        <Box display="flex" height={20} mb="12px">
          <Typography fontSize="14px" fontWeight={600} >
            Registration Info
          </Typography>
        </Box>

        <Card
          sx={{
            mb: "14px",
            borderRadius: "4px",
            boxShadow:
              "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column" }}>
          </Box>
          <CardContent
            sx={{
              ":last-child": { p: "10px" },
            }}
          >
            <Box width="100%" display="flex" mb="3px" >
              <Box width="50%" display="flex">
                <Typography fontSize="14px" fontWeight={500} color="black" align="left" >
                  Booking No: {PreRegBookingInfo?.Booking?.BookingNo}
                </Typography>
              </Box>
              <Box width="50%" display="flex" sx={{ flexDirection: 'row-reverse' }}>
                <Typography fontSize="14px" fontWeight={500} color="secondary">
                  {dateFormat(PreRegBookingInfo?.ArrivalDate, "dd/mm/yyyy")} - {dateFormat(PreRegBookingInfo?.DepartureDate, "dd/mm/yyyy")}
                </Typography>
              </Box>
            </Box>
            <Box width="100%" display="flex" mb="3px">
              <Box width="50%" display="flex" alignItems={"center"}>
                <Typography fontSize="14px" fontWeight={500} color="black" >
                  {PreRegBookingInfo?.Guest?.Contact?.FullName}
                </Typography>
              </Box>
            </Box>
            <Box width="100%" display="flex" mb="3px">
              <Box width="50%" display="flex" alignItems={"center"}>
                <Typography fontSize="14px" fontWeight={500} color="black" style={{ textDecoration: "underline" }}>
                  {PreRegBookingInfo?.Guest?.Contact?.Email ? PreRegBookingInfo?.Guest?.Contact?.Email : ""}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>

        <Box display="flex" height={20} mb="12px">
          <Typography fontSize="14px" fontWeight={600} >
            Check In Information
          </Typography>
        </Box>

        <Card
          sx={{
            mb: "14px",
            borderRadius: "4px",
            boxShadow:
              "0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)",
          }}
        >
          <CardContent sx={{ ":last-child": { p: "10px" }, }}>
            <Box width="100%" display="flex" mb="3px">
              <Box width="50%" mb="16px" pr="8px">
                {state?.Nationality === 'Malaysian' || state?.Nationality === null ? (
                  <PatternFormat
                    required
                    label={'ID NO.(NRIC/MyKAD)'}
                    displayType="input"
                    type="text"
                    value={state?.NRICNo}
                    customInput={TextField}
                    name="NRIC"
                    format={"######-##-####"}
                    mask="_"
                    variant="standard"
                    onChange={(e: any) => {
                      dispatch({
                        type: 'NRICNo',
                        payload: e.target.value
                      })
                      dispatch({ type: 'IsTouched', payload: true })

                    }}
                    onBlur={() => {
                      dispatch({
                        type: 'DateofBirth',
                        payload:
                          ValidateDob(state?.NRICNo) ||
                          null,
                      })
                    }}


                  />) : (<TextField
                    required
                    label={'Passport No.'}
                    type="text"
                    value={state?.NRICNo}
                    name="NRIC"
                    variant="standard"
                    onChange={(e: any) => {
                      dispatch({
                        type: 'NRICNo',
                        payload: e.target.value
                      })
                    }}
                  />

                )}


              </Box>
             
                
              <Box width="50%" >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <MuiThemeProvider theme={theme}>
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-dialog"
                      label="Date of birth"
                      name="DateofBirth"
                      views={['year', 'month', 'date']}
                      value={state.DateofBirth}
                      onChange={date => {
                        dispatch({
                          type: 'DateofBirth',
                          payload: date,
                        })
                        dispatch({ type: 'IsTouched', payload: true })
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      style={{ marginTop: "-1px" }}
                      allowKeyboardControl
                      clearable
                    />
                  </MuiThemeProvider>
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
            <Box width="100%"  mb="10px">
              <MuiTelInput
                label="Mobile No."
                fullWidth
                defaultCountry="MY"
                value={state.MobileNo}
                variant="standard"
                onChange={e =>
                  dispatch({
                    type: 'MobileNo',
                    payload: e
                  })
                }
              />
              </Box>
          
        <Box width="100%"  mb="10px">
         <TextField
          name="Address"
          fullWidth
          label="Address"
          variant="standard"
          value={state.Address }
          onChange={e =>
            dispatch({
              type: 'Address',
              payload: e.target.value
            })
          }
        />
   <br></br>
      
        
        <Box width="100%" display="flex" mb="3px">
        <Box width="50%" mb="16px" pr="8px">
        <TextField
          name="City"
          label="City"
          variant="standard"
          value={state?.City || ''}
 
          // required
 
          onChange={e => {
            dispatch({
              type: 'City',
              payload: e.target.value
            })
          }}
        />
         </Box>
         <Box width="50%" mb="16px" pr="8px">
        <TextField
          name="Postcode"
          label="Postcode"

          variant="standard"
          value={state?.PostCode || ''}
          // required
          onChange={e => {
            dispatch({
              type: 'PostCode',
              payload: e.target.value
            })
          }}
          
        />
              </Box>
           </Box>
           <Box width="100%" display="flex" mb="10px">
          
              {state?.Country === 'Malaysia' ? (
          <TextField
            name="State"
            label="State"
            style={{width:'100%'}}
            value={state?.State || ''}
            variant="standard"
            select
            required
            // error={!GuestInput?.State}
            // helperText={!GuestInput?.State && 'State must not be empty!'}
            onChange={e => {
              dispatch({
                type: 'State',
                payload: e.target.value
              })
            }}
            
          >
            
            {StateEnum.map(option => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            name="State"
            className="right"
            label="State"
            margin="normal"
            variant="standard"
            
            // required
            value={state?.State || ''}
            onChange={e => {
              dispatch({
                type: 'State',
                payload: e.target.value
              })
            }}
          />
        )}
        </Box>
            </Box>
            <Box width="100%" display="flex" mb="3px">
              <Box width="50%" mb="16px" pr="8px">
                <Autocomplete
                  fullWidth
                  id="nationality"
                  options={NationalityEnum}
                  value={state?.Nationality}
                  getOptionLabel={(option) => option || ""}
                  onChange={(_, data) =>
                    dispatch({
                      type: 'Nationality',
                      payload: data as string,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Nationality"
                      variant="standard"
                    />
                  )}
                />
              </Box>
              <Box width="50%" mb="16px" pl="8px">
                <Autocomplete
                  fullWidth
                  id="country"
                  options={CountryEnum}
                  value={state?.Country}
                  getOptionLabel={(option) => option || ""}
                  onChange={(_, data) =>
                    dispatch({
                      type: 'Country',
                      payload: data as string,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label="Country"
                      variant="standard"
                    />
                  )}
                />
              </Box>
            </Box>
            <Box width="100%"  mb="10px">
         <TextField
          name="SpecialRequest"
          fullWidth
          label="Special Request"
          variant="standard"
          value={state.SpecialRequest }
          onChange={e =>
            dispatch({
              type: 'SpecialRequest',
              payload: e.target.value
            })
          }
        />
   <br></br>
   </Box>
            <Box width="100%" display="flex" mb="8px">
              <Typography fontSize="14px" fontWeight={100} color="grey">
                Attach IC/Passport*
              </Typography>
            </Box>
            <Box width="100%" display="flex" mb="8px" >
              <Box width="50%" display="flex" pl="26px">
                <Typography fontSize="14px" fontWeight={100} color="grey" align="left">
                  Front
                </Typography>
              </Box>
              <Box width="50%" display="flex" pl="28px">
                <Typography fontSize="14px" fontWeight={100} color="grey">
                  Back
                </Typography>
              </Box>
            </Box>

            <Box width="100%" display="flex" mb="10px">
              <Box width="50%" mb="16px">
                <Box
                  display="flex"
                  width="130px"
                  height="100px"
                  ml="13%"
                  style={{ borderRadius: '5px' }}
                  component="label"
                  onClick={(e: any) => inputFile.current.Click}
                >
                  {state?.IcPhotoFront
                    ? (
                      <Box
                        boxShadow="0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)"
                        style={{ borderRadius: '5px' }}
                        className='margin-auto'
                      >
                        <img
                          src={uploadFile?.uploadFileFront}
                          alt="empty"
                          loading="lazy"
                          height="100px"
                          width="130px"
                          style={{ borderRadius: "5px" }}
                        />
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        component="span"
                        width="130px"
                        height="100px"
                        style={{ borderRadius: '5px' }}
                        sx={{ border: '1px dashed grey' }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <AddBoxIcon style={{ color: "#f5ab35" }} />
                      </Box>
                    )
                  }
                  <input
                    hidden
                    ref={inputFile}
                    type="file"
                    accept="image/jpeg, image/png"
                    onChange={async (e: any) => {
                      handleUploadFile("Front", e);
                      await dispatch({
                        type: "icphotoFront",
                        payload: null,
                      });
                      await dispatch({
                        type: "icphotoFront",
                        payload: e.target.files[0],
                      });
                    }}
                  />
                </Box>
              </Box>
              <Box width="50%" mb="16px" >
                <Box
                  display="flex"
                  width="130px"
                  height="100px"
                  ml="15%"
                  component="label"
                  onClick={(e: any) => inputFile.current.Click}
                >
                  {state?.IcPhotoBack
                    ? (
                      <Box
                        boxShadow="0 3px 1px -2px transparent, 2px 5px 4px -2px rgb(0 0 0 / 15%), -2px -1px 4px -2px rgb(0 0 0 / 15%)"
                        style={{ borderRadius: '5px' }}
                        className='margin-auto'
                      >
                        <img
                          src={uploadFile?.uploadFileBack}
                          alt="empty"
                          loading="lazy"
                          height="100px"
                          width="130px"
                          style={{ borderRadius: "5px" }}
                        />
                      </Box>
                    ) : (
                      <Box
                        display="flex"
                        width="130px"
                        height="100px"
                        style={{ borderRadius: '5px' }}
                        sx={{ border: '1px dashed grey' }}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <AddBoxIcon style={{ color: "#f5ab35" }} />
                      </Box>
                    )
                  }
                  <input
                    hidden
                    ref={inputFile}
                    type="file"
                    accept="image/jpeg, image/png"
                    onChange={async (e: any) => {
                      handleUploadFile("Back", e);
                      await dispatch({
                        type: "icphotoBack",
                        payload: null,
                      });
                      await dispatch({
                        type: "icphotoBack",
                        payload: e.target.files[0],
                      });
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <FormGroup sx={{ mb: "16px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    checked={check}
                    onChange={() => {
                      check === true
                        ? setCheck(false)
                        : setCheck(true)

                      dispatch({
                        type: 'IsTerm',
                        payload: check,
                      })
                    }}
                  />
                }
                label={
                  <Typography fontSize="13px" fontWeight={500}>
                    I acknowledge and accept the hotel{" "}
                    <Typography
                      color="#f5ab35"
                      fontSize="13px"
                      fontWeight={500}
                      component="span"
                      style={{ textDecoration: "underline", cursor: "pointer" }}
                      onClick={() => {
                        setCheck(!check);
                        handleOpenTerm();
                      }}
                    >
                      Terms and Condition
                    </Typography>
                  </Typography>
                }
              />
            </FormGroup>
            <FormGroup sx={{ mb: "16px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    checked={checkPdpa}
                    onChange={() => {
                      checkPdpa === true
                        ? setCheckPdpa(false)
                        : setCheckPdpa(true)

                      dispatch({
                        type: 'IsPDPA',
                        payload: checkPdpa,
                      })
                    }}
                  />
                }
                label={
                  <Typography fontSize="13px" fontWeight={500} >
                    I acknowledge and accept the hotel{" "}
                    <Typography
                      color="#f5ab35"
                      fontSize="13px"
                      fontWeight={500}
                      component="span"
                      style={{ textDecoration: "underline" }}
                      onClick={() => {
                        setCheckPdpa(!checkPdpa);
                        handleOpenPdpa();
                      }}
                    >
                      PDPA
                    </Typography>
                  </Typography>
                }
              />
            </FormGroup>
            <Box width="100%" display="flex">
              <Typography fontSize="14px" fontWeight={100} color="grey">
                E-Signature
              </Typography>
            </Box>
            <FormGroup sx={{ mb: "3px", flexDirection: 'row-reverse' }}>
              <div onClick={clear}>
                <FormControlLabel
                  control={
                    <RefreshIcon
                      style={{ fontSize: "18px", color: "grey" }}
                    />
                  }
                  label={
                    <Typography
                      fontSize="14px"
                      fontWeight={100}
                      color="grey"
                    >
                      Clear
                    </Typography>
                  }
                />
              </div>
            </FormGroup>
            <Box
              height="100px"
              display="flex"
              mb="50px"
              style={{ borderRadius: '5px', borderColor: 'grey' }}
              sx={{ border: 1 }}
              justifyContent="center"
              alignItems="center"
            >
              {state.SignatureUrl ? (
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    color: "transparent",
                  }}
                >
                  <img
                    src={state.SignatureUrl}
                    alt="sig"
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                  />
                </div>
              ) : (
                <SignaturePad
                  ref={ref => {
                    signCanvas.current = ref
                  }}
                  onBegin={() => {
                    if (signCanvas.current && state.IsPDPA && state.IsTerm) {
                      signCanvas.current = null
                    } else {
                      signCanvas.current = signCanvas.current
                    }
                  }}
                  onEnd={() => dispatch({ type: "IsSignature", payload: true })}
                  canvasProps={{
                    className: 'm-b-8',
                    style: { width: "100%", height: "100%" }
                  }}
                />
              )
              }
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Dialog open={openTerm} onClose={handleCloseTerm}>
        <DialogTitle sx={{ fontWeight: "bold", color: "#f5ab35", backgroundColor: "#fff5e7" }}>
          Terms and Condition
        </DialogTitle>
        <DialogContent>
          {HotelDetails?.RegCardTnC ? (
            <div dangerouslySetInnerHTML={{ __html: HotelDetails.RegCardTnC }} />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseTerm}
            sx={{
              backgroundColor: "#f5ab35",
              "&:hover": {
                backgroundColor: "#f5ab35", // Keep the same background color on hover
              },
              "&:active": {
                backgroundColor: "#f5ab35", // Keep the same background color when clicked
              },
            }}
          >            <Typography style={{ fontWeight: "bold", color: "#ffffff" }}>
              Close
            </Typography>

          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openPdpa} onClose={handleClosePdpa}>
        <DialogTitle sx={{ fontWeight: "bold", color: "#f5ab35", backgroundColor: "#fff5e7" }}>
          PDPA
        </DialogTitle>
        <DialogContent>
          {HotelDetails?.PDPA ? (
            <div dangerouslySetInnerHTML={{ __html: HotelDetails.PDPA }} />
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClosePdpa}
            sx={{
              backgroundColor: "#f5ab35",
              "&:hover": {
                backgroundColor: "#f5ab35", // Keep the same background color on hover
              },
              "&:active": {
                backgroundColor: "#f5ab35", // Keep the same background color when clicked
              },
            }}
          >
            <Typography style={{ fontWeight: "bold", color: "#ffffff" }}>
              Close
            </Typography>

          </Button>
        </DialogActions>
      </Dialog>

      <SnackBarMsg open={openSnackBar} message={snackBarMessage} />


      <Footer
        options={[
          {
            name: 'Submit',
            onClick: () => {
              onSubmit()
            },
            disabled: check === false || checkPdpa === false ||
              !state?.IsSignature || !state?.NRICNo ||
              !state?.Nationality || !state?.Country ||
              !state?.IcPhotoFront || !state?.IcPhotoBack
          },
        ]}
      />
      {/* </MainWrapper> */}
    </div>
  )
}
